import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import '../styles/index.scss';
import {
    linkIcon,
    linkIconContainer,
    copyrightText,
} from '../styles/footer.module.scss';

import { FaGithub, FaEthereum, FaLinkedin } from 'react-icons/fa';

const Footer = (props) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                    personalLinks {
                        githubProfile
                        linkedInProfile
                        etherScan
                    }
                }
            }
        }
    `);

    return (
        <footer>
            <div>
                <ul className={linkIconContainer}>
                    <li>
                        <a
                            className={linkIcon}
                            aria-label="GitHub Link"
                            href={
                                data.site.siteMetadata.personalLinks
                                    .githubProfile
                            }
                        >
                            <FaGithub />
                        </a>
                    </li>
                    <li>
                        <a
                            className={linkIcon}
                            aria-label="LinkedIn Link"
                            href={
                                data.site.siteMetadata.personalLinks
                                    .linkedInProfile
                            }
                        >
                            <FaLinkedin />
                        </a>
                    </li>
                    <li>
                        <a
                            className={linkIcon}
                            aria-label="Ethereum Address Link"
                            href={
                                data.site.siteMetadata.personalLinks.etherScan
                            }
                        >
                            <FaEthereum />
                        </a>
                    </li>
                </ul>
                <p className={copyrightText}>
                    © {new Date().getFullYear()} {data.site.siteMetadata.author}
                </p>
            </div>
        </footer>
    );
};

export default Footer;
