import React, { useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import '../styles/index.scss';
import * as headerStyles from '../styles/header.module.scss';

import { FaBars, FaTimes } from 'react-icons/fa';

const Header = (props) => {
    // React hook to store state of nav and helper functions
    const [navActive, setNavActive] = useState(false);
    const handleNavToggle = () => setNavActive(!navActive);
    const closeNav = () => setNavActive(false);

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                }
            }
        }
    `);

    return (
        <header className={headerStyles.container}>
            <Link to="/">
                <StaticImage
                    src="../images/logo.svg"
                    alt={data.site.siteMetadata.author}
                    placeholder="blurred"
                    layout="constrained"
                    height={80}
                />
            </Link>
            <nav>
                <ul
                    className={
                        navActive
                            ? `${headerStyles.navBarActive}`
                            : `${headerStyles.navBar}`
                    }
                >
                    <li>
                        <Link
                            className={headerStyles.navComponent}
                            activeClassName={headerStyles.currentNavComponent}
                            onClick={closeNav}
                            to="/"
                        >
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link
                            className={headerStyles.navComponent}
                            activeClassName={headerStyles.currentNavComponent}
                            onClick={closeNav}
                            to="/blog"
                        >
                            Blog
                        </Link>
                    </li>
                    <li>
                        <Link
                            className={headerStyles.navComponent}
                            activeClassName={headerStyles.currentNavComponent}
                            onClick={closeNav}
                            to="/about"
                        >
                            About
                        </Link>
                    </li>
                    <li>
                        <Link
                            className={headerStyles.navComponent}
                            activeClassName={headerStyles.currentNavComponent}
                            onClick={closeNav}
                            to="/contact"
                        >
                            Contact
                        </Link>
                    </li>
                </ul>
                <button
                    className={headerStyles.mobileMenu}
                    onClick={handleNavToggle}
                    onKeyDown={handleNavToggle}
                >
                    {navActive ? <FaTimes /> : <FaBars />}
                </button>
            </nav>
        </header>
    );
};

export default Header;
