import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './header';
import Footer from './footer';

import '../styles/index.scss';
import { container, content } from '../styles/layout.module.scss';

const Layout = (props) => {
    return (
        <div class={container}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>tomking.io</title>
            </Helmet>

            <div class={content}>
                <Header />
                {props.children}
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
